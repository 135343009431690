<template>
  <div class="bg-light rounded-3 p-4 p-md-5 mb-3">
    <!-- Title -->
    <h2 class="h4 mb-5 text-center">
<!--      <i class="fi-info-circle text-primary fs-5 mt-n1 me-2"></i>-->
      Please select the skills required
    </h2>
    <!-- / Title -->

    <!-- Subspecialities -->
    <div class="mx-auto">
      <div class="form-check" v-for="(subspeciality, i) in subspecialitiesToShow" :key="i">
        <input :value="subspeciality"
               v-model="selectedSubspecialities"
               class="form-check-input"
               :id="generateCheckboxId('subspeciality', i)"
               type="checkbox">
        <label class="form-check-label" :for="generateCheckboxId('subspeciality', i)">{{ subspeciality.name }}</label>
      </div>
    </div>
    <!-- / Subspecialities -->

    <!-- Footer Navigation -->
    <div class="wizard-footer-navigation">
      <!-- Previous -->
      <a class="btn btn-primary btn-lg rounded-pill me-3" href="#"
         @click.prevent="handlePreviousButton">
        <i class="fi-chevron-left fs-sm me-2"></i>
        Previous
      </a>
      <!-- / Previous -->

      <!-- Next -->
      <a class="btn btn-primary btn-lg rounded-pill ms-sm-auto" href="#"
         @click.prevent="handleNextButton" :class="{'disabled': !this.canGoForward}">
        Next
        <i class="fi-chevron-right fs-sm ms-2"></i>
      </a>
      <!-- / Next -->
    </div>
    <!-- / Footer Navigation -->
  </div>
</template>

<script>
import Forms from "../../../../mixins/Forms";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "SubspecialitySelector",
  mixins: [Forms],

  computed: {
    ...mapGetters('findALawyer', {
      context: 'context',
      speciality: 'speciality',
      subspecialities: 'subspecialities'
    }),

    canGoForward() {
      return this.subspecialities.length;
      // return false;
    },

    subspecialitiesToShow() {
      let vm = this;
      let allSubspecialities = this.context ? this.context['subspecialities'] : [];
      return _.filter(allSubspecialities, s => {
        return s.speciality_id === vm.speciality.id;
      });
    },

    selectedSubspecialities: {
      get() {
        return this.subspecialities;
      },
      set(vals) {
        this.setSubspecialities(vals);
      }
    }
  },

  created() {
    if (this.subspecialitiesToShow.length == 0) {
      this.setSubspecialities([]);
      this.setIsLocationImportant(null);
      this.incrementWizardStage();
    }
  },

  methods: {
    ...mapActions('findALawyer', {
      setSpeciality: 'setSpeciality',
      setSubspecialities: 'setSubspecialities',
      incrementWizardStage: 'incrementWizardStage',
      setIsLocationImportant: 'setIsLocationImportant'
    }),

    handlePreviousButton() {
      this.setSpeciality(null);
    },

    handleNextButton() {
      if (!this.canGoForward) {
        return false;
      }
      this.setIsLocationImportant(null);
      this.incrementWizardStage();
    }
  }
}
</script>

<style scoped>
.form-check-input:checked {
  /* border-color: rgb(var(--primary-colour)); */
}
</style>