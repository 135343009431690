<template>
  <vue-final-modal v-model="showModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header border-bottom-0">
          <h5 class="modal-title">
            Location Filter
          </h5>
          <button type="button" class="btn-close" aria-label="Close" @click="$emit('close')"></button>
        </div>
        <div class="modal-body">

          <label class="form-label" for="autocompleteInput">Address</label>
          <GMapAutocomplete class="form-control" id="autocompleteInput"
                            placeholder="Search for a location"
                            @place_changed="setPlace"
          >
          </GMapAutocomplete>

          <div class="mt-4">
            <a class="btn btn-primary btn-lg rounded-pill" href="#"
               :class="{'disabled': !canSave}"
               @click.prevent="setLocation">
              {{ this.locationFilter.isLocationImportant ? 'Change Location' : 'Set Location' }}
            </a>

            <a v-if="locationFilter.isLocationImportant"
               @click.prevent="removeLocationFilter"
               href="#" class="text-decoration-none btn">
              Remove Filter
            </a>
          </div>

        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
export default {
  name: "ChangeLocationFilterModal",
  props: ['show', 'locationFilter'],
  computed: {
    showModal: {
      get() {
        return this.show;
      },
      set(val) {
        if (!val) {
          this.$emit('close');
        }
      }
    },
    canSave() {
      return (this.longitude && this.latitude);
    }
  },
  data() {
    return {
      latitude: null,
      longitude: null
    }
  },
  methods: {
    setPlace(place) {
      let {lat, lng} = place.geometry.location.toJSON();
      this.latitude = lat;
      this.longitude = lng;
    },

    setLocation() {
      if (!this.canSave) {
        return false;
      }
      if (!this.locationFilter.radius) {
       this.locationFilter.radius = 1;
      }
      this.locationFilter.isLocationImportant = true;
      this.locationFilter.latitude = this.latitude;
      this.locationFilter.longitude = this.longitude;
      this.$emit('close');
    },

    removeLocationFilter() {
      this.locationFilter.isLocationImportant = false;
      this.locationFilter.latitude = null;
      this.locationFilter.longitude = null;
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>