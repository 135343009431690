<template>
  <div class="card card-hover h-100" @click.prevent="select">
    <div class="card-body text-center">
      <div class="text-center pt-3 mb-4">
        <img v-if="temporaryLink" :src="temporaryLink.url" class="" alt="Icon"/>
      </div>
      <h5 class="card-title">{{ speciality.name }}</h5>
    </div>
  </div>
</template>

<script>
import Images from '@/mixins/Images'
import temporaryUrls from "../../../../../api/temporary-urls";
import {mapActions} from "vuex";

export default {
  props: ["speciality"],
  mixins: [Images],
  data() {
    return {
      isLoading: false,
      temporaryLink: null
    }
  },
  created() {
    if (this.speciality && this.speciality.icon) {
      this.attemptGetFromCacheOrLoad();
      // this.loadTemporaryLink();
    }
  },
  methods: {
    ...mapActions('temporaryImages', {
      getTemporaryImageByKey: 'getTemporaryImageByKey',
      addTemporaryImageToCache: 'addTemporaryImageToCache'
    }),

    select() {
      this.$emit('selected');
    },

    attemptGetFromCacheOrLoad() {
      this.getTemporaryImageByKey('speciality-' + this.speciality.id).then(r => {
        this.temporaryLink = r;
      }).catch(() => {
        this.loadTemporaryLink();
      })
    },

    loadTemporaryLink() {
      let vm = this;
      vm.isLoading = false;
      temporaryUrls.getSpecialityIconTemporaryUrl(this.speciality.id).then(r => {
        vm.isLoading = false;
        vm.temporaryLink = r.data;
        vm.addTemporaryImageToCache({
          key: 'speciality-' + this.speciality.id,
          value: vm.temporaryLink
        });
      }).catch(e => {
        console.log(e);
        vm.isLoading = false;
        vm.temporaryLink = null;
      });
    }
  }
};
</script>

<style scoped>
.card-hover:hover {
  background-color: rgba(var(--bs-secondary-rgb), 0.6);
  cursor: pointer;
}
</style>