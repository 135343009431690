<template>
  <div class="bg-light rounded-3 p-4 p-md-5 mb-3">
    <!-- Title -->
    <h2 class="h4 mb-5 text-center">
<!--      <i class="fi-info-circle text-primary fs-5 mt-n1 me-2"></i>-->
      Please choose a speciality
    </h2>
    <!-- / Title -->

    <!-- Loading -->
    <div v-if="isLoading" class="text-center">
      <spinner></spinner>
    </div>
    <!-- / Loading -->

    <!-- Tiles -->
    <div class="row">
      <div
          class="col-md-3 mb-3"
          v-for="(speciality, i) in specialities"
          :key="i"
      >
        <speciality-card
            :speciality="speciality"
            @selected="handleSpecialitySelected(speciality)"
        ></speciality-card>
      </div>
    </div>
    <!-- / Tiles -->

    <!-- Looking for something else -->
    <div v-if="profession && profession.name == 'Lawyer'" class="bg-secondary text-dark rounded-3 p-4 mt-3 looking-for-something-else">
      <div class="left">
        <div class="fw-bold mb-1 title">
          Looking for something else?
        </div>
        <p class="mb-0">
          Syndesi are your professional partner, tell us more and we'll find you a professional.
        </p>
      </div>
      <div class="right">
        <a href="#" class="btn btn-primary" @click.prevent="openSpecialRequestModal">
          Find me someone else
        </a>
      </div>
    </div>
    <!-- / Looking for something else -->

    <special-request-modal :visible="specialRequestModalVisible" @close="closeSpecialRequestModal"></special-request-modal>
  </div>
</template>

<script>
import Forms from "@/mixins/Forms";
import Spinner from "@/components/Spinner";
import axios from "axios";
import SpecialityCard from "./SpecialitySelector/SpecialityCard.vue";
import {mapActions, mapGetters} from "vuex";
import SpecialRequestModal from "../../../../components/SpecialRequestModal";

export default {
  mixins: [Forms],
  components: {SpecialRequestModal, Spinner, SpecialityCard},
  data() {
    return {
      isLoading: false,
      specialRequestModalVisible: false
    };
  },
  computed: {
   ...mapGetters('findALawyer', {
     context: 'context',
     profession: 'profession'
   }),

    specialities() {
    //  return this.context ? this.context['specialities'] : [];
      if (this.context && this.context['specialities']) {
        return this.context['specialities'].filter(speciality => {
          return speciality.profession_id === this.profession.id;
        });
      } else {
        return [];
      }
    }
  },
  methods: {
    ...mapActions("findALawyer", {
      setSpeciality: "setSpeciality",
      setSubspecialities: "setSubspecialities",
      incrementWizardStage: "incrementWizardStage"
    }),

    handleSpecialitySelected(speciality) {
      this.setSpeciality(speciality);
      this.setSubspecialities([]);
      // this.incrementWizardStage();
    },

    openSpecialRequestModal() {
      this.specialRequestModalVisible = true;
    },

    closeSpecialRequestModal() {
      this.specialRequestModalVisible = false;
    }
  },
};
</script>

<style scoped lang="scss">
.looking-for-something-else {
  display: flex;
  .left {
    flex-grow: 1;
  }
  .right {
    flex-shrink: 1;
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 1000px) {
  .looking-for-something-else {
    flex-direction: column;
    text-align: center;

    .title {
      margin-bottom: 10px !important;
    }

    .right {
      text-align: center;
      justify-content: center;
      margin-top: 20px;
    }
  }
}
</style>