<template>
  <div>
    <login-and-signup-modal v-if="!loggedIn" @registered="saveJob" ref="loginAndSignupModal"></login-and-signup-modal>

    <!-- Actions -->
    <div class="bg-light rounded-3 p-4 p-md-4 mb-3">
      <!-- Footer Navigation -->
      <div class="wizard-footer-navigation mt-0 d-flex justify-content-between">
        <!-- Previous -->
        <a class="btn btn-primary btn-lg rounded-pill me-3" href="#" @click.prevent="handlePreviousButton">
          <i class="fi-chevron-left fs-sm me-2"></i>
          Previous
        </a>
        <!-- / Previous -->

        <!-- Next -->
        <a class="btn btn-primary btn-lg rounded-pill ms-sm-auto" href="#" @click.prevent="handleNextButton"
          :class="{ 'disabled': !this.canGoForward }">
          Submit Job
          <i class="fi-chevron-right fs-sm ms-2"></i>
        </a>
        <!-- / Next -->
      </div>
      <!-- / Footer Navigation -->
    </div>
    <!-- / Actions -->

    <div class="row">
      <div class="col-md-6 mb-3">
        <!-- Selected Lawyers -->
        <div class="bg-light rounded-3 p-4 p-md-5  h-100">
          <!-- Title -->
          <h2 class="h4 mb-5 text-center">
            <!--        <i class="fi-users text-primary fs-5 mt-n1 me-2"></i>-->
            Selected Professionals
          </h2>
          <!-- / Title -->

          <!-- Review section - lawyers -->
          <div class="review-section">
            <lawyer-card v-for="(lawyer, i) in lawyersSelected" :key="i" class="mb-3"
              @view-more-details-for="viewMoreDetailsFor(lawyer)" :lawyer="lawyer"></lawyer-card>
          </div>
          <!-- / Review section - lawyers -->
        </div>
        <!-- / Selected Lawyers -->
      </div>
      <div class="col-md-6 mb-3">
        <!-- Job Details -->
        <div class="bg-light rounded-3 p-4 p-md-5  h-100">
          <!-- Title -->
          <h2 class="h4 mb-5 text-center">
            <!--        <i class="fi-info-circle text-primary fs-5 mt-n1 me-2"></i>-->
            Job Details
          </h2>
          <!-- / Title -->

          <div class="text-center mb-4">
            <b>Title:</b><br>
            {{ jobTitle }}
          </div>

          <div class="text-center">
            <b>Description:</b><br>
            {{ jobDescription }}
          </div>
        </div>
        <!-- / Job Details -->
      </div>
    </div>

    <lawyer-more-details-modal @close="viewingMoreDetailsFor = null" :hide-toggle="true"
      :lawyer="viewingMoreDetailsFor"></lawyer-more-details-modal>


    <!-- Actions -->
    <!--    <div class="bg-light rounded-3 p-4 p-md-5">-->
    <!--      &lt;!&ndash; Footer Navigation &ndash;&gt;-->
    <!--      <div class="wizard-footer-navigation mt-0">-->
    <!--        &lt;!&ndash; Previous &ndash;&gt;-->
    <!--        <a class="btn btn-primary btn-lg rounded-pill me-3" href="#"-->
    <!--           @click.prevent="handlePreviousButton">-->
    <!--          <i class="fi-chevron-left fs-sm me-2"></i>-->
    <!--          Previous-->
    <!--        </a>-->
    <!--        &lt;!&ndash; / Previous &ndash;&gt;-->

    <!--        &lt;!&ndash; Next &ndash;&gt;-->
    <!--        <a class="btn btn-primary btn-lg rounded-pill ms-sm-auto" href="#"-->
    <!--           @click.prevent="handleNextButton" :class="{'disabled': !this.canGoForward}">-->
    <!--          Submit Job-->
    <!--          <i class="fi-chevron-right fs-sm ms-2"></i>-->
    <!--        </a>-->
    <!--        &lt;!&ndash; / Next &ndash;&gt;-->
    <!--      </div>-->
    <!--      &lt;!&ndash; / Footer Navigation &ndash;&gt;-->
    <!--    </div>-->
    <!-- / Actions -->
  </div>
</template>

<script>
import LawyerCard from "./ReviewJob/LawyerCard";
import { mapActions, mapGetters } from "vuex";
import LawyerMoreDetailsModal from "./ReviewLawyers/Results/LawyerMoreDetailsModal";
import LoginAndSignupModal from './ReviewJob/LoginAndSignupModal.vue';

export default {
  name: "ReviewJob",
  components: { LawyerCard, LawyerMoreDetailsModal, LoginAndSignupModal },
  data() {
    return {
      viewingMoreDetailsFor: null,
      loginAndSignupModalVisible: false
    }
  },
  computed: {
    ...mapGetters('findALawyer', {
      lawyersSelected: 'lawyersSelected',
      jobTitle: 'jobTitle',
      jobDescription: 'jobDescription'
    }),

    ...mapGetters('auth', {
      loggedIn: 'loggedIn'
    }),

    canGoForward() {
      return true;
    }
  },
  methods: {
    ...mapActions('findALawyer', {
      decrementWizardStage: 'decrementWizardStage',
      saveJob: 'saveJob'
    }),

    handlePreviousButton() {
      this.decrementWizardStage();
    },

    handleNextButton() {
      if (this.loggedIn) {
        this.saveJob();
      } else {
        this.showLoginAndSignupModal();
      }
    },

    showLoginAndSignupModal() {
      this.$refs['loginAndSignupModal'].openModal();
    },

    viewMoreDetailsFor(lawyer) {
      this.viewingMoreDetailsFor = lawyer;
    }
  }
}
</script>

<style scoped>
.review-section {
  text-align: center;
}
</style>