<template>
  <div class="bg-light rounded-3 p-4 p-md-5 mb-3">
    <!-- Title -->
    <h2 class="h4 mb-5 text-center">
<!--      <i class="fi-map text-primary fs-5 mt-n1 me-2"></i>-->
      Is the professional's location important?
    </h2>
    <!-- / Title -->

    <!-- Radio -->
    <div class="radio-wrapper text-center">
      <div class="form-check form-check-inline pe-4">
        <input :value="false" v-model="isLocationImportantLocal"
               class="form-check-input" id="isLocationImportantNo" type="radio" name="radios-stacked">
        <label class="form-check-label" for="isLocationImportantNo">No</label>
      </div>
      <div class="form-check form-check-inline">
        <input :value="true" v-model="isLocationImportantLocal"
               class="form-check-input" id="isLocationImportantYes" type="radio" name="radios-stacked">
        <label class="form-check-label" for="isLocationImportantYes">Yes</label>
      </div>
    </div>
    <!-- / Radio -->

    <!-- Footer Navigation -->
    <div class="wizard-footer-navigation">
      <!-- Previous -->
      <a class="btn btn-primary btn-lg rounded-pill me-3" href="#"
         @click.prevent="handlePreviousButton">
        <i class="fi-chevron-left fs-sm me-2"></i>
        Previous
      </a>
      <!-- / Previous -->

      <!-- Next -->
      <a class="btn btn-primary btn-lg rounded-pill ms-sm-auto" href="#"
         @click.prevent="handleNextButton" :class="{'disabled': !this.canGoForward}">
        Next
        <i class="fi-chevron-right fs-sm ms-2"></i>
      </a>
      <!-- / Next -->
    </div>
    <!-- / Footer Navigation -->
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
  name: "IsLocationImportantSelector",

  data() {
   return {
     isLocationImportantLocal: null
   }
  },

  computed: {
    ...mapGetters('findALawyer', {
      isLocationImportant: 'isLocationImportant'
    }),

    canGoForward() {
      return this.isLocationImportantLocal !== null;
    }
  },

  methods: {
    ...mapActions('findALawyer', {
      decrementWizardStage: 'decrementWizardStage',
      incrementWizardStage: 'incrementWizardStage',
      setIsLocationImportant: 'setIsLocationImportant',
      setLatitude: 'setLatitude',
      setLongitude: 'setLongitude'
    }),

    handlePreviousButton() {
      this.decrementWizardStage();
    },

    handleNextButton() {
      if (!this.canGoForward) {
        return false;
      }

      if (this.isLocationImportantLocal === true) {
        this.setLatitude(null);
        this.setLongitude(null);
        this.setIsLocationImportant(true);
      } else {
        this.setIsLocationImportant(false);
        this.incrementWizardStage();
      }
    }
  }
}
</script>

<style scoped>
.form-check-input:checked {
  /* border-color: rgb(var(--primary-colour)); */
}
</style>