<template>
<div class="is-favourite-indicator text-primary">
  <i v-if="isFavourite" class="fi fi-heart-filled"></i>
</div>
</template>

<script>
import Providers from "../../../../../../../mixins/Providers";
import {mapActions} from "vuex";
export default {
  name: "IsFavouriteIndicator",
  props: ['userRole'],
  mixins: [Providers],
  computed: {
    isFavourite() {
      return this.isProviderFavourited(this.userRole);
    }
  }
}
</script>

<style scoped>
.is-favourite-indicator {
  display: inline;
  font-size: 15px;
}
</style>