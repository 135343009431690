<template>
  <div>
    <!-- Not submitted -->
    <div v-if="!submitted">
      <!-- Errors -->
      <div class="p-4 rounded bg-danger text-white mb-3" v-if="serverErrors.length">
        <ul style="margin-bottom: 0 !important;">
          <li v-for="error in serverErrors">{{ error }}</li>
        </ul>
      </div>
      <!-- / Errors -->

      <!-- Speciality Required -->
      <div class="mb-4">
        <label class="form-label" for="speciality-required">Speciality Required</label>
        <input :disabled="isSaving" v-model="form.speciality_required"
               placeholder="e.g. Commercial lawyer"
               class="form-control" type="text" id="speciality-required">
      </div>
      <!-- / Speciality Required -->

      <!--- Job description -->
      <div class="mb-2">
        <label class="form-label" for="jobDescription">Job Description</label>
        <textarea v-model="form.job_description" placeholder="Please provide some details to help us with context, including whether fixed or variable fees are preferred"
                  class="form-control" id="jobDescription" rows="5"></textarea>
      </div>
      <!-- / Job description -->

      <!-- Submit btn -->
      <button
          type="button"
          class="d-inline btn btn-primary mt-3"
          :disabled="isSaving"
          @click.prevent="save"
      >
        <spinner v-if="isSaving" class="spinner-border-sm me-2"></spinner>
        {{ isSaving ? 'Submitting' : 'Submit Request' }}
      </button>
      <!-- / Submit btn -->
    </div>
    <!-- / Not submitted -->

    <!-- Submitted -->
    <div v-if="submitted">
      <p>Thank you for your request. A member of our team will be in touch within 2 working days.</p>
    </div>
    <!-- / Submitted -->
  </div>
</template>

<script>
import Spinner from "../Spinner";
import axios from 'axios';
import Forms from "../../mixins/Forms";
export default {
  name: "SpecialRequestForm",
  components: {Spinner},
  mixins: [Forms],
  data() {
    return {
      form: {
        speciality_required: '',
        job_description: ''
      },
      isSaving: false,
      submitted: false,
      serverErrors: []
    }
  },
  methods: {
    save() {
      let vm = this;
      vm.isSaving = true;
      vm.serverErrors = [];

      axios.post(window.API_BASE + '/special-requests', this.form).then(() => {
        vm.isSaving = false;
        vm.submitted = true;
      }).catch(e => {
        vm.setErrors(e, 'Error submitting request');
        vm.isSaving = false;
      });

    }
  }
}
</script>

<style scoped>

</style>