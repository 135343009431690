<template>
  <div>
    <!-- Topbar -->
    <lawyers-selected-topbar class="mb-3"></lawyers-selected-topbar>
    <!-- / Topbar -->

    <!-- Results -->
    <lawyer-result-card v-for="(lawyer, i) in lawyers"
                        class="mb-3"
                        :lawyer="lawyer"
                        :match-percentage="calculateMatchPercentage(lawyer)"
                        @view-more-details="viewMoreDetailsFor(lawyer)"
                        :key="i"></lawyer-result-card>
    <!-- / Results -->

    <!-- No results -->
    <div
        class="bg-syndesi-gray text-dark rounded-3 p-4 mt-3 no-results-banner" v-if="lawyers.length == 0 && !isSearching">
      <div class="left">
        <div class="fw-bold mb-1 title">
          There were no results for your exact criteria.
        </div>
        <p class="mb-0">
          Below are some close matches. <br>
          Alternatively, we can help you find who you need.
        </p>
      </div>
      <div class="right">
        <a href="#" class="btn btn-primary" @click.prevent="openSpecialRequestModal">
          Help me find someone
        </a>
      </div>
    </div>
    <!-- / No results -->

    <!--    &lt;!&ndash; No results &ndash;&gt;-->
    <!--    <no-results v-if="lawyers.length === 0" bg="syndesi-gray" text="dark">-->

    <!--      There were no lawyers for your exact criteria, but we found these close matches:-->
    <!--    </no-results>-->
    <div v-if="lawyers.length === 0" class="mt-3">
      <lawyer-result-card v-for="(lawyer, i) in lawyerSuggestions"
                          class="mb-3"
                          :lawyer="lawyer"
                          @view-more-details="viewMoreDetailsFor(lawyer)"
                          :key="i"></lawyer-result-card>
    </div>
    <!-- / No results -->

    <special-request-modal :visible="specialRequestModalVisible" @close="closeSpecialRequestModal"></special-request-modal>


    <lawyer-more-details-modal @close="viewingMoreDetailsFor = null"
                               :lawyer="viewingMoreDetailsFor"></lawyer-more-details-modal>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import LawyerResultCard from "./Results/LawyerResultCard";
import LawyerMoreDetailsModal from "./Results/LawyerMoreDetailsModal";
import NoResults from "../../../../../components/NoResults";
import LawyersSelectedTopbar from "./Results/LawyersSelectedTopbar";
import SpecialRequestModal from "../../../../../components/SpecialRequestModal";

const _ = require('lodash');

export default {
  name: "Results",

  props: ['isSearching'],

  components: {LawyersSelectedTopbar, NoResults, LawyerMoreDetailsModal, LawyerResultCard, SpecialRequestModal},

  data() {
    return {
      viewingMoreDetailsFor: null,
      specialRequestModalVisible: false
    }
  },

  computed: {
    ...mapGetters('findALawyer', {
      lawyerResults: 'lawyerResults',
      lawyerSuggestions: 'lawyerSuggestions',
      subspecialities: 'subspecialities'
    }),

    lawyers() {
      if (this.lawyerResults && this.lawyerResults.data) {
        return this.lawyerResults.data;
      }
      return [];
    }
  },

  methods: {
    viewMoreDetailsFor(lawyer) {
      this.viewingMoreDetailsFor = lawyer;
    },

    openSpecialRequestModal() {
      this.specialRequestModalVisible = true;
    },

    closeSpecialRequestModal() {
      this.specialRequestModalVisible = false;
    },

    calculateMatchPercentage(lawyer) {
      if (this.subspecialities.length && lawyer.subspecialities_count !== undefined) {
        return (lawyer.subspecialities_count / this.subspecialities.length) * 100
      }
      return 100;
    }
  }
}
</script>

<style scoped lang="scss">
.no-results-banner {
  display: flex;

  .left {
    flex-grow: 1;
  }

  .right {
    flex-shrink: 1;
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 1000px) {
  .no-results-banner {
    flex-direction: column;
    text-align: center;

    .title {
      margin-bottom: 10px !important;
    }

    .right {
      text-align: center;
      justify-content: center;
      margin-top: 20px;
    }
  }
}
</style>