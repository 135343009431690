<template>
    <vue-final-modal v-model="visible">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">
                    <h5 class="modal-title">
                        Just a few more details so professionals can contact you...
                    </h5>
                    <button type="button" class="btn-close" aria-label="Close" @click="$emit('close')"></button>
                </div>
                <div class="modal-body">

                    <!-- Create account -->
                    <div v-if="tab == 'create-account'">

                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-4">
                                    <label class="form-label" for="name">Your Name <span
                                            class="text-danger">*</span></label>
                                    <input v-model="form.name" class="form-control form-control-lg" type="text" id="name"
                                        :disabled="isSaving" placeholder="Enter your name" required>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="mb-4">
                                    <label class="form-label" for="email">Your Email <span
                                            class="text-danger">*</span></label>
                                    <input v-model="form.email" class="form-control form-control-lg" type="text" id="email"
                                        :disabled="isSaving" placeholder="Enter your email" required>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-4">
                                    <label class="form-label" for="phone">Phone Number<span
                                            class="text-danger">*</span></label>
                                    <input v-model="form.phone" class="form-control form-control-lg" type="tel"
                                        id="phone" :disabled="isSaving" placeholder="Enter your mobile number" required>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-4">
                                    <label class="form-label" for="password">Password <span
                                            class="text-danger">*</span></label>
                                    <input v-model="form.password" class="form-control form-control-lg" type="password"
                                        id="password" :disabled="isSaving" placeholder="Enter a password" required>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="mb-4">
                                    <label class="form-label" for="companyName">Company Name (If Applicable)</label>
                                    <input v-model="form.company_name" class="form-control form-control-lg" type="text"
                                        id="companyName" :disabled="isSaving" placeholder="Enter your company name">
                                </div>
                            </div>
                        </div>

                        <div class="mb-2 pt-2">
                            <button class="btn btn-primary btn-lg rounded-pill w-100" :disabled="isSaving"
                                @click="handleCreateAccountButton">
                                <spinner v-if="isSaving" class="spinner-border-sm me-2"></spinner>
                                <i v-else class="fi-lock fs-sm me-2"></i>
                                <span>{{ isSaving ? 'Creating' : 'Create' }}</span> Account
                            </button>
                        </div>

                        <div class="mt-4 mb-4">
                            <i>Alternatively...</i>
                        </div>

                        <div class="mb-2">
                            <button class="btn btn-dark btn-lg rounded-pill" style="background-color: rgb(10, 102, 194)"
                                :disabled="isSaving">
                                <spinner v-if="isSaving" class="spinner-border-sm me-2"></spinner>
                                <i v-else class="fi-linkedin fs-sm me-2"></i>
                                Sign Up With LinkedIn
                            </button>
                        </div>

                    </div>
                    <!-- / Create account -->

                </div>
            </div>
        </div>
    </vue-final-modal>
</template>
  
<script>
import Spinner from "../../../../../components/Spinner.vue";
import axios from 'axios';
import Forms from "../../../../../mixins/Forms";
import { mapGetters, mapActions } from 'vuex';

export default {
    name: "LoginAndSignupModal",
    mixins: [Forms],
    components: { Spinner },
    data() {
        return {
            visible: false,
            isSaving: false,
            serverErrors: [],
            form: {
                name: '',
                email: '',
                phone: '',
                password: '',
                password_confirmation: '',
                company_name: ''
            },
            tab: 'create-account' // create-account or login
        }
    },
    computed: {
        ...mapGetters('whitelabelConfiguration', {
            whitelabelConfigurationId: 'whitelabelConfigurationId'
        })
    },
    methods: {
        ...mapActions('auth', {
            handleUserRegistered: 'handleUserRegistered'
        }),

        openModal() {
            this.visible = true;
        },

        handleCreateAccountButton() {
            let vm = this;
            vm.isSaving = true;
            axios.post(window.API_BASE + '/client-register', {
                name: vm.form.name,
                email: vm.form.email,
                password: vm.form.password,
                // password_confirmation: vm.form.password_confirmation,
                password_confirmation: vm.form.password,
                phone: vm.form.phone,
                company_name: vm.form.company_name,
                wcid: vm.whitelabelConfigurationId
            }).then(r => {
                vm.handleUserRegistered(r.data).then(r => {
                    vm.isSaving = false;
                    vm.$emit('registered');
                }).catch(e => {
                    console.log(e);
                    vm.isSaving = false;
                    vm.setAndNotifyErrors(e);
                });
            }).catch(e => {
                console.log(e);
                vm.isSaving = false;
                vm.setAndNotifyErrors(e);
            });
        }
    }
}
</script>
  
<style scoped></style>