<template>
  <div class="card card-body pb-1">
    <speciality-filter :favourites="favourites"
                       :local-filters="localFilters"></speciality-filter>
  </div>
</template>

<script>
import SpecialityFilter from "./FavouriteFilters/SpecialityFilter";

export default {
  name: "FavouriteFilters",
  components: {SpecialityFilter},
  props: ['favourites', 'localFilters']
}
</script>

<style scoped>

</style>