<template>
  <div class="sticky-top" style="margin-top:-6rem;padding-top: 6rem">
    <div class="bg-light rounded-3 p-4">
      <h5>Filter Results</h5>

      <div class="sidebar-group">
        <!-- Speciality -->
        <div class="sidebar-section">
          <b>Speciality</b><br>
          <multiselect class="mt-2" height="200" mode="single" :can-clear="false" :canDeselect="false"
            v-model="specialityLocalId" :options="specialityOptions" searchable @change="clearSelectedSubspecialities">
          </multiselect>
        </div>
        <!-- / Speciality -->

        <!-- Subspecialities -->
        <div class="sidebar-section">
          <b>Subspecialities</b><br>
          <multiselect class="mt-2" mode="tags" v-model="subspecialityLocalIds" :options="subspecialityOptions" searchable
            :can-clear="false" :closeOnSelect="false">
            <template v-slot:tag="{ option, handleTagRemove, disabled }">
              <div class="multiselect-tag is-user">
                {{ truncateLabel(option.label) }}
                <span v-if="!disabled" class="multiselect-tag-remove"
                  @mousedown.prevent="handleTagRemove(option, $event)">
                  <span class="multiselect-tag-remove-icon"></span>
                </span>
              </div>
            </template>
          </multiselect>
        </div>
        <!-- / Subspecialities -->
      </div>

      <div class="sidebar-group">
        <!-- Location -->
        <div class="sidebar-section">
          <div class="d-flex">
            <div class="flex-grow-1">
              <b>
                {{ locationFilterLocal.isLocationImportant ? 'Max Distance' : 'Location' }}
              </b>
            </div>
            <div class="flex-shrink-1" v-if="locationFilterLocal.isLocationImportant">
              <a href="#" @click.prevent="openChangeLocationFilterModal" class="text-decoration-none">
                Change location
              </a>
            </div>
          </div>
          <change-location-filter-modal :location-filter="locationFilterLocal" v-if="showChangeLocationFilterModal"
            :show="showChangeLocationFilterModal" @close="showChangeLocationFilterModal = false">
          </change-location-filter-modal>
          <!-- No location filter -->
          <div class="mt-2" v-if="!locationFilterLocal.isLocationImportant">
            <a href="#" @click.prevent="openChangeLocationFilterModal" class="text-decoration-none">+ Add location
              filter</a>
          </div>
          <!-- / No location filter -->
          <!-- Change location filter -->
          <div class="" v-if="locationFilterLocal.isLocationImportant">
            <div class="range-slider">
              <Slider class="radius-slider" :min="5" :step="5" :format="radiusSliderFormatting"
                v-model="locationFilterLocal.radius" />
            </div>
          </div>
          <!-- / Change location filter -->
        </div>
        <!-- / Location -->
      </div>

      <div class="sidebar-group">
        <!-- Premium Only -->
        <div class="sidebar-section">
          <b>Only Show Premium Professionals</b>

          <div class="mt-2">
            <div class="form-check form-switch">
              <input v-model="premiumProvidersOnlyLocal" type="checkbox" id="only-show-premium-lawyers" class="form-check-input">
              <label title="" for="only-show-premium-lawyers" class="form-check-label"></label>
            </div>
          </div>
        </div>
        <!-- / Premium Only -->
      </div>

      <!--      <div class="sidebar-group">-->
      <!--        &lt;!&ndash; Budget slider &ndash;&gt;-->
      <!--        <div class="sidebar-section">-->
      <!--          <b>Hourly Rate</b>-->
      <!--          <div class="range-slider">-->
      <!--            <Slider :min="contextMinHourlyRateInPounds"-->
      <!--                    :step="10"-->
      <!--                    :max="contextMaxHourlyRateInPounds"-->
      <!--                    :format="sliderFormatting" v-model="budgetLocal"/>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        &lt;!&ndash; / Budget slider &ndash;&gt;-->
      <!--      </div>-->

      <!-- Search -->
      <div class="sidebar-footer">
        <a class="btn btn-primary btn-lg rounded-pill w-100" href="#" :class="{ 'disabled': !hasUnsavedChanges }"
          @click.prevent="saveAndSearch">
          <i class="fi-search fs-sm me-2"></i>
          Search
        </a>
      </div>
      <!-- / Search -->
    </div>
  </div>
</template>

<script>
import Slider from '@vueform/slider';
import Multiselect from '@vueform/multiselect'
import ChangeLocationFilterModal from "./FilterSidebar/ChangeLocationFilterModal";
import { mapGetters, mapActions } from 'vuex';
import eventBus from "../../../../../eventBus";

export default {
  name: "FilterSidebar",
  components: { Slider, Multiselect, ChangeLocationFilterModal },
  computed: {
    ...mapGetters('findALawyer', {
      context: 'context',
      speciality: 'speciality',
      subspecialities: 'subspecialities',
      isLocationImportant: 'isLocationImportant',
      latitude: 'latitude',
      longitude: 'longitude',
      radius: 'radius',
      budgetMin: 'budgetMin',
      budgetMax: 'budgetMax',
      contextMinHourlyRateInPounds: 'contextMinHourlyRateInPounds',
      contextMaxHourlyRateInPounds: 'contextMaxHourlyRateInPounds',
      premiumProvidersOnly: 'premiumProvidersOnly'
    }),

    specialityOptions() {
      let options = {};
      _.each(this.context['specialities'], s => {
        options[s.id] = s.name;
      });
      return options;
    },

    subspecialityOptions() {
      let vm = this;
      let options = {};
      let subspecialitiesForSpeciality = _.filter(this.context['subspecialities'], s => {
        return Number(s['speciality_id']) === Number(vm.specialityLocalId);
      });
      _.each(subspecialitiesForSpeciality, s => {
        options[s.id] = s.name;
      });
      return options;
    },

    hasUnsavedChanges() {
      let isUnsaved = false;

      // Speciality local
      if (String(this.speciality.id) !== String(this.specialityLocalId)) {
        isUnsaved = true;
      }

      // Subspecialities local
      let selectedSubspecialityIds = _.map(this.subspecialities, 'id');
      let localSubspecialityIds = _.map(this.subspecialityLocalIds, i => parseInt(i));
      let diffPassOne = _.difference(selectedSubspecialityIds, localSubspecialityIds);
      let diffPassTwo = _.difference(localSubspecialityIds, selectedSubspecialityIds);
      if (_.merge(diffPassOne, diffPassTwo).length) {
        isUnsaved = true;
      }

      // Location - Is location important
      if (this.locationFilterLocal.isLocationImportant !== this.isLocationImportant) {
        isUnsaved = true;
      }

      // Location - latitude
      if (this.locationFilterLocal.latitude !== this.latitude) {
        isUnsaved = true;
      }

      // Location - radius
      if (this.locationFilterLocal.radius !== this.radius) {
        isUnsaved = true;
      }

      // Premium providers only
      if (this.premiumProvidersOnlyLocal !== this.premiumProvidersOnly) {
        isUnsaved = true;
      }

      // Budget min
      if (this.budgetMin !== this.budgetLocal[0]) {
        isUnsaved = true;
      }

      // Budget max
      if (this.budgetMax !== this.budgetLocal[1]) {
        isUnsaved = true;
      }

      return isUnsaved;
    }
  },
  created() {
    this.setup();
  },
  data() {
    return {
      specialityLocalId: null,
      subspecialityLocalIds: [],

      showChangeLocationFilterModal: false,
      locationFilterLocal: {
        isLocationImportant: null,
        latitude: null,
        longitude: null,
        radius: null
      },

      premiumProvidersOnlyLocal: false,

      valueRange: [0, 200],
      budgetLocal: [],
      sliderFormatting: {
        prefix: '£',
        thousand: ',',
        decimals: 0
      },
      radiusSliderFormatting: {
        suffix: ' mi',
        thousand: ',',
        decimals: 0
      }
    }
  },
  methods: {
    ...mapActions('findALawyer', {
      searchLawyers: 'searchLawyers',
      setSpeciality: 'setSpeciality',
      setSubspecialities: 'setSubspecialities',
      setBudgetMin: 'setBudgetMin',
      setBudgetMax: 'setBudgetMax',
      setIsLocationImportant: 'setIsLocationImportant',
      setLatitude: 'setLatitude',
      setLongitude: 'setLongitude',
      setRadius: 'setRadius',
      setPremiumProvidersOnly: 'setPremiumProvidersOnly'
    }),

    setup() {
      this.specialityLocalId = this.speciality ? this.speciality.id : null;
      this.subspecialityLocalIds = this.subspecialities ? _.map(this.subspecialities, 'id') : [];

      this.locationFilterLocal.isLocationImportant = this.isLocationImportant;
      this.locationFilterLocal.latitude = this.latitude;
      this.locationFilterLocal.longitude = this.longitude;
      this.locationFilterLocal.radius = this.radius;

      this.premiumProvidersOnlyLocal = this.premiumProvidersOnly;

      this.budgetLocal = [this.budgetMin, this.budgetMax]
    },

    clearSelectedSubspecialities() {
      this.subspecialityLocalIds = [];
    },

    openChangeLocationFilterModal() {
      this.showChangeLocationFilterModal = true;
    },

    saveAndSearch() {
      if (!this.hasUnsavedChanges) {
        return false;
      }

      this.setSpeciality(
        _.find(this.context['specialities'], { id: Number(this.specialityLocalId) })
      );
      this.setSubspecialities(
        _.filter(this.context['subspecialities'], s => (_.includes(_.map(this.subspecialityLocalIds, i => parseInt(i)), parseInt(s.id))))
      );

      this.setIsLocationImportant(this.locationFilterLocal.isLocationImportant);
      this.setLatitude(this.locationFilterLocal.latitude);
      this.setLongitude(this.locationFilterLocal.longitude);
      this.setRadius(this.locationFilterLocal.radius);

      this.setPremiumProvidersOnly(this.premiumProvidersOnlyLocal);

      this.setBudgetMin(this.budgetLocal[0]);
      this.setBudgetMax(this.budgetLocal[1]);

      eventBus.$emit('fal-searched-from-sidebar');
      this.searchLawyers();
    },

    truncateLabel(label) {
      if (label.length > 26) {
        return label.substring(0, 26) + '...'
      }
      return label;
    }
  }
}
</script>

<style lang="scss" scoped>
.range-slider {
  padding-top: 4rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}

.sidebar-section {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.sidebar-group {
  //margin-top: 1rem;
  margin-bottom: 1rem;
}

.sidebar-footer {
  margin-top: 2.2rem;
}

.multiselect-tag {
  background: rgb(var(--primary-colour));
}
</style>