<template>
  <div class="bg-syndesi-gray text-dark rounded-3 px-4 py-3">
    <div class="flex-wrapper">
      <div class="left">
        <!-- Avatars -->
        <div class="avatars">
          <div class="avatar" v-for="lawyer in lawyersSelected">
<!--            <img width="40"-->
<!--                 class="rounded-circle" :src="getAvatarSrc(lawyer)" alt="Avatar"/>-->
            <user-role-avatar width="40" class="rounded-circle" :user-role="lawyer"></user-role-avatar>
          </div>
        </div>
        <!-- / Avatars -->

        {{ lawyersSelected.length }} {{ lawyersSelected.length == 1 ? 'professional' : 'professionals' }} selected
      </div>
      <div class="right">
        <button type="button"
                @click.prevent="handleContinue"
                :disabled="!canContinue"
                class="btn btn-sm btn-primary">
          Next
          <i class="fi-chevron-right fs-sm ms-2"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import Images from "../../../../../../mixins/Images";
import UserRoleAvatar from "../../../../../../components/UserRoleAvatar";

export default {
  name: "LawyersSelectedTopbar",
  components: {UserRoleAvatar},
  mixins: [Images],
  computed: {
    ...mapGetters('findALawyer', {
      lawyersSelected: 'lawyersSelected'
    }),

    canContinue() {
      return this.lawyersSelected.length;
    }
  },
  methods: {
    ...mapActions('findALawyer', {
      incrementWizardStage: 'incrementWizardStage'
    }),

    handleContinue() {
      if (!this.canContinue) {
        return false;
      }
      this.incrementWizardStage();
    }
  }
}
</script>

<style scoped lang="scss">
.avatars {
  display: inline-block;
  margin-right: 15px;

  .avatar {
    display: inline;
    &:first-child {
      margin-left: 0;
    }
    margin-left: -14px;
  }
}

.flex-wrapper {
  display: flex;
  align-items: center;

  .left {
    font-weight: bold;
    flex-grow: 1;
  }

  .right {
    flex-shrink: 1;
  }
}
</style>