<template>
  <div class="bg-light rounded-3 p-4 p-md-5 mb-3">
    <!-- Title -->
    <h2 class="h4 mb-5 text-center">
<!--      <i class="fi-map text-primary fs-5 mt-n1 me-2"></i>-->
      {{ locationSelected ? 'What is the acceptable distance to travel?' : 'What is your preferred location?' }}
    </h2>
    <!-- / Title -->

    <!-- Google autocomplete -->
    <div v-if="!locationSelected">
      <label class="form-label" for="autocompleteInput">Address</label>
      <GMapAutocomplete class="form-control" id="autocompleteInput"
                        placeholder="Start typing a postcode, a town, or a place"
                        @place_changed="setPlace"
      >
      </GMapAutocomplete>
    </div>
    <!-- / Google autocomplete -->

    <!-- Map finder -->
    <div v-if="locationSelected" class="row">
      <!-- Radius -->
      <div class="mt-5 mb-5">
        <Slider class="radius-slider" :min="5" :step="5"
                :format="sliderFormatting" v-model="radiusInput"/>
      </div>
      <!-- / Radius -->

      <GoogleMap :api-key="googleMapsApiKey"
                 style=""
                 class="map-container"
                 :center="map.center"
                 :zoom="zoomFromRadius"
      >
        <Marker ref="mapMarker"
                :options="{ position: map.center, draggable: true }" @dragend="handleMarkerLocationChanged"/>
        <Circle :options="radiusCircle"/>
      </GoogleMap>
    </div>
    <!-- / Map finder -->

    <!-- Radius -->
    <!-- / Radius -->

    <!-- Footer Navigation -->
    <div class="wizard-footer-navigation">
      <!-- Previous -->
      <a class="btn btn-primary btn-lg rounded-pill me-3" href="#"
         @click.prevent="handlePreviousButton">
        <i class="fi-chevron-left fs-sm me-2"></i>
        Previous
      </a>
      <!-- / Previous -->

      <!-- Next -->
      <a class="btn btn-primary btn-lg rounded-pill ms-sm-auto" href="#"
         @click.prevent="handleNextButton" :class="{'disabled': !this.canGoForward}">
        Next
        <i class="fi-chevron-right fs-sm ms-2"></i>
      </a>
      <!-- / Next -->
    </div>
    <!-- / Footer Navigation -->
  </div>
</template>

<script>
import {GoogleMap, Marker, Circle} from 'vue3-google-map'
import {mapActions, mapGetters} from 'vuex';
import Slider from '@vueform/slider';

export default {
  name: "LocationSelector",
  components: {GoogleMap, Marker, Circle, Slider},
  data() {
    return {
      googleMapsApiKey: 'AIzaSyCX3eiBzNBkCIYA6V_EXAdDaxHwT5ikVxM',
      sliderFormatting: {
        suffix: ' mi',
        thousand: ',',
        decimals: 0
      },
      latitudeLongitudeLocal: null
    }
  },
  created() {
    // if (!this.latitude && !this.longitude) {
    //   this.setLatitude(51.5455491139732);
    //   this.setLongitude(0.6636191274479154);
    // }
    if (!this.radius) {
      this.setRadius(5);
    }
  },
  computed: {
    ...mapGetters('findALawyer', {
      latitude: 'latitude',
      longitude: 'longitude',
      radius: 'radius'
    }),

    locationSelected() {
      return (this.latitude && this.longitude);
    },

    radiusInput: {
      get() {
        return this.radius;
      },
      set(val) {
        this.setRadius(val);
      }
    },

    canGoForward() {
      if (!(this.latitude || this.longitude)) {
        return this.latitudeLongitudeLocal;
      }
      // return false;
      // return true;
      return (this.latitude && this.longitude);
    },

    map() {
      return {
        center: {
          lat: this.latitude,
          lng: this.longitude
        }
      }
    },

    radiusCircle() {
      return {
        center: this.map.center,
        radius: this.radius * 1000, // convert to metres
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
      }
    },

    zoomFromRadius() {
      return this.getBaseLog(2, 40000 / (this.miToKm(this.radius) / 2)) - 2.2;
    }
  },
  methods: {
    ...mapActions('findALawyer', {
      setIsLocationImportant: 'setIsLocationImportant',
      incrementWizardStage: 'incrementWizardStage',
      setLatitude: 'setLatitude',
      setLongitude: 'setLongitude',
      setRadius: 'setRadius'
    }),

    miToKm(km) {
      return km * 1.60934;
    },

    getBaseLog(x, y) {
      return Math.log(y) / Math.log(x);
    },

    setPlace(place) {
      let latLng = place.geometry.location.toJSON();
      this.latitudeLongitudeLocal = latLng;
    },

    handleMarkerLocationChanged(params, op) {
      this.setPosition(
          params['latLng'].toJSON()
      );
    },

    setPosition(params) {
      let {lat, lng} = params;
      this.setLatitude(lat);
      this.setLongitude(lng);
    },

    handlePreviousButton() {
      if (this.latitude && this.longitude) {
        this.setLatitude(null);
        this.setLongitude(null);
        this.latitudeLongitudeLocal = null;
      } else {
        this.setIsLocationImportant(null);
      }
    },

    handleNextButton() {
      if (!this.latitude || !this.longitude) {
        this.setPosition(this.latitudeLongitudeLocal);
      } else {
        this.incrementWizardStage();
      }
    }
  }
}
</script>

<style scoped>
.map-container {
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
  height: 400px;
}

.radius-slider {
  max-width: 800px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  width: 100%;
}
</style>