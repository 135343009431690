<template>
  <vue-final-modal v-model="showModal">
    <div class="modal-dialog lawyer-more-details-modal" role="document" v-if="lawyer">
      <div class="modal-content">
        <div class="modal-header border-bottom-0">
          <h5 class="modal-title">
            <!-- Lawyer Details -->
            Professional Details
            <!--            {{ lawyer.user.name }}-->
          </h5>
          <button type="button" class="btn-close" aria-label="Close" @click="$emit('close')"></button>
        </div>
        <div class="modal-body">
          <bio-section :lawyer="lawyer">
            <div class="mt-3" v-if="!hideToggle">
              <button type="button" class="btn btn-primary btn-sm" @click.prevent="toggleLawyerSelectedAndCloseModal">
                {{ isLawyerSelected ? 'Deselect Professional' : 'Select Professional' }}
              </button>
            </div>
          </bio-section>
          <hr>
          <specialities-section class="specialities-section" :lawyer="lawyer"></specialities-section>
        </div>
        <!--        <div class="modal-footer">-->
        <!--          <button type="button" class="btn btn-secondary btn-sm" @click.prevent="$emit('close')">Close</button>-->
        <!--          <button type="button" class="btn btn-primary btn-sm" @click.prevent="toggleLawyerSelectedAndCloseModal">-->
        <!--            {{ isLawyerSelected ? 'Deselect Lawyer' : 'Select Lawyer' }}-->
        <!--          </button>-->
        <!--        </div>-->
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import BioSection from "./LawyerMoreDetailsModal/BioSection";
import SpecialitiesSection from "./LawyerMoreDetailsModal/SpecialitiesSection";
import {mapGetters, mapActions} from "vuex";
import Favourites from "../../../../../Favourites";

const _ = require('lodash');

export default {
  name: "LawyerMoreDetailsModal",
  components: {SpecialitiesSection, BioSection},
  props: ['lawyer', 'hideToggle'],
  computed: {
    ...mapGetters('findALawyer', {
      lawyersSelected: 'lawyersSelected'
    }),

    isLawyerSelected() {
      return _.find(this.lawyersSelected, {id: this.lawyer.id}) !== undefined;
    },

    showModal: {
      get() {
        return this.lawyer !== null;
      },
      set(val) {
        if (!val) {
          this.$emit('close');
        }
      }
    }
  },
  methods: {
    ...mapActions('findALawyer', {
      toggleLawyerSelected: 'toggleLawyerSelected'
    }),

    toggleLawyerSelectedAndCloseModal() {
      this.toggleLawyerSelected(this.lawyer);
      this.$emit('close');
    }
  }
}
</script>

<style lang="scss">
.lawyer-more-details-modal {
  .specialities-section {
    max-height: 300px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }
}
</style>