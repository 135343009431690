<template>
  <div class="bg-light rounded-3 p-4 p-md-5 mb-3">
    <!-- Title -->
    <h2 class="h4 mb-5 text-center">
<!--      <i class="fi-info-circle text-primary fs-5 mt-n1 me-2"></i>-->
      What professional are you looking for?
    </h2>
    <!-- / Title -->

    <!-- Loading -->
    <div v-if="isLoading" class="text-center">
      <spinner></spinner>
    </div>
    <!-- / Loading -->

    <!-- Tiles -->
    <div class="row">
      <div
          class="col-md-3 mb-3"
          v-for="(profession, i) in professions"
          :key="i"
      >
        <profession-card
            :profession="profession"
            @selected="handleProfessionSelected(profession)"
        ></profession-card>
      </div>
    </div>
    <!-- / Tiles -->
  </div>
</template>

<script>
import Forms from "@/mixins/Forms";
import Spinner from "@/components/Spinner";
// import axios from "axios";
import ProfessionCard from "./ProfessionSelector/ProfessionCard.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  mixins: [Forms],
  components: {Spinner, ProfessionCard},
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
   ...mapGetters('findALawyer', {
     context: 'context'
   }),

    professions() {
     return this.context ? this.context['professions'] : [];
    }
  },
  methods: {
    ...mapActions("findALawyer", {
      setProfession: "setProfession",
      setSpeciality: "setSpeciality",
      setSubspecialities: "setSubspecialities",
      incrementWizardStage: "incrementWizardStage"
    }),

    handleProfessionSelected(speciality) {
      this.setProfession(speciality);
      this.setSpeciality(null);
      this.setSubspecialities([]);
      this.incrementWizardStage();
    }
  },
};
</script>

<style scoped lang="scss">
.looking-for-something-else {
  display: flex;
  .left {
    flex-grow: 1;
  }
  .right {
    flex-shrink: 1;
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 1000px) {
  .looking-for-something-else {
    flex-direction: column;
    text-align: center;

    .title {
      margin-bottom: 10px !important;
    }

    .right {
      text-align: center;
      justify-content: center;
      margin-top: 20px;
    }
  }
}
</style>