<template>
  <vue-final-modal v-model="showModal">
    <div class="modal-dialog lawyer-more-details-modal" role="document">
      <div class="modal-content">
        <div class="modal-header border-bottom-0">
          <h5 class="modal-title">
            Submit Special Request
          </h5>
          <button type="button" class="btn-close" aria-label="Close" @click="$emit('close')"></button>
        </div>
        <div class="modal-body">
          <special-request-form></special-request-form>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import SpecialRequestForm from "./SpecialRequestModal/SpecialRequestForm";
export default {
  name: "SpecialRequestModal",
  components: {SpecialRequestForm},
  props: ['visible'],
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(val) {
        if (!val) {
          this.$emit('close');
        }
      }
    }
  }
}
</script>

<style lang="scss">
.special-request-modal {
  .specialities-section {
    max-height: 300px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }
}
</style>