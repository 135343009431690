<template>
  <div>
    <!-- Main results section -->
    <div class="row mt-4">
      <div class="col-lg-4">
        <filter-sidebar></filter-sidebar>
      </div>
      <div class="col-lg-8">
        <!--        <div v-if="isSearching" class="text-center">-->
        <!--          <spinner></spinner>-->
        <!--        </div>-->
        <results :is-searching="isSearching"></results>

        <!-- Pagination -->
        <!--        <div class="mt-5"-->
        <!--             v-if="!isSearching && lawyerResults && lawyerResults.last_page > 1">-->
        <!--          <pagination v-model="page" :records="lawyerResults.total" :per-page="lawyerResults.per_page"-->
        <!--                      @paginate="fetch"/>-->
        <!--        </div>-->
        <!-- / Pagination -->

        <VueEternalLoading :key="eternalLoadingKey"
                           :load="fetch"
                           position="top">
          <template #no-more></template>
        </VueEternalLoading>

      </div>
    </div>
    <!-- / Main results section -->

    <!-- Footer Navigation -->
    <div class="wizard-footer-navigation">
      <!-- Previous -->
      <a class="btn btn-primary btn-lg rounded-pill me-3" href="#"
         @click.prevent="handlePreviousButton">
        <i class="fi-chevron-left fs-sm me-2"></i>
        Previous
      </a>
      <!-- / Previous -->

      <!-- Next -->
      <a class="btn btn-primary btn-lg rounded-pill ms-sm-auto" href="#"
         @click.prevent="handleNextButton" :class="{'disabled': !this.canGoForward}">
        Next
        <i class="fi-chevron-right fs-sm ms-2"></i>
      </a>
      <!-- / Next -->
    </div>
    <!-- / Footer Navigation -->
  </div>
</template>

<script>
import Spinner from "../../../../components/Spinner";
import FilterSidebar from "./ReviewLawyers/FilterSidebar";
import Results from "./ReviewLawyers/Results";
import {mapGetters, mapActions} from 'vuex';
import {VueEternalLoading} from '@ts-pro/vue-eternal-loading';
import eventBus from "../../../../eventBus";

export default {
  name: "ReviewLawyers",

  components: {Spinner, FilterSidebar, Results, VueEternalLoading},

  data() {
    return {
      page: 1,
      eternalLoadingKey: 1
    }
  },

  created() {
    let vm = this;
    eventBus.$on('fal-searched-from-sidebar', function () {
      vm.page = 1;
      vm.eternalLoadingKey += 1;
    });

    this.searchLawyers();
  },

  computed: {
    ...mapGetters('findALawyer', {
      isSearching: 'isSearching',
      lawyersSelected: 'lawyersSelected',
      isLocationImportant: 'isLocationImportant',
      lawyerResults: 'lawyerResults',
      resultsPerPage: 'resultsPerPage'
    }),

    canGoForward() {
      return this.lawyersSelected.length;
    }
  },

  methods: {
    ...mapActions('findALawyer', {
      setIsLocationImportant: 'setIsLocationImportant',
      decrementWizardStage: 'decrementWizardStage',
      incrementWizardStage: 'incrementWizardStage',
      searchLawyers: 'searchLawyers'
    }),

    fetch({loaded, noMore}) {
      this.searchLawyers({page: this.page}).then((r) => {
        // if (this.page == 1) {
        //   window.scrollTo(0, 0);
        // }
        if (!r.data || r.data.length == 0) {
          noMore();
        } else {
          loaded(this.lawyerResults.length, this.resultsPerPage);
          if (r.data.length < this.resultsPerPage) {
            noMore();
          }
        }
        this.page += 1;
      }).catch(e => {
        console.log(e);
      });
    },

    handlePreviousButton() {
      // if (this.isLocationImportant === false) {
      //   this.setIsLocationImportant(null);
      // }
      this.decrementWizardStage();
    },

    handleNextButton() {
      this.incrementWizardStage();
    }
  }
}
</script>

<style scoped>

</style>