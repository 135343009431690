<template>
  <div class="status-filter-wrapper">
    <h4 class="mb-3 h5">Filter By Speciality</h4>
    <ul class="list-unstyled fs-sm">
      <li v-for="option in specialityOptions">
        <a class="nav-link fw-normal d-flex align-items-center px-0 py-1"
           :class="{'active': isSelected(option)}"
           href="#" @click.prevent="selectOption(option)">
          <i :class="getIconClass(option)"></i>
          <span>{{ option.label }}</span>
          <!--          <span class="text-muted ms-auto">(3)</span>-->
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
const _ = require('lodash');
export default {
  name: "SpecialityFilter",
  props: ['localFilters', 'favourites'],
  data() {
    return {
      filterKey: 'speciality_id'
    }
  },
  computed: {
    specialityOptions() {
      let options = [];

      options.push({
        value: null,
        label: 'All Specialities',
        icon: 'fi-users'
      });

      let userRoles = _.map(this.favourites, 'bookmarked_user_role');
      let specialities = _.uniqBy(_.flattenDeep(_.map(userRoles, 'specialities')), 'id');

      _.each(specialities, speciality => {
        options.push({
          value: speciality.id,
          label: speciality.name,
          icon: 'fi-check-circle'
        });
      });

      return options;
    }
  },
  methods: {
    isSelected(option) {
      return this.localFilters[this.filterKey] == option.value;
    },
    selectOption(option) {
      this.localFilters[this.filterKey] = option.value;
    },
    getIconClass(option) {
      return option.icon + ' opacity-70 me-2';
    }
  }
}
</script>

<style scoped>
.status-filter-wrapper {
  max-width: 15rem;
}
</style>