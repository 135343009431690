<template>
  <div class="container mt-5 mb-md-4 py-5">
    <breadcrumb class="mb-3 mb-md-4 pt-md-3" :crumbs="crumbs"></breadcrumb>
    <loading-screen :is-loading="isSaving"></loading-screen>

    <!-- Page content -->
    <div class="row justify-content-center pb-sm-2">
      <div class="col-lg-12 col-xl-11">
        <!-- Page title -->
        <div class="text-center pb-4 mb-3">
          <!-- <h1 class="h2">Find a Lawyer</h1> -->
          <h1 class="h2">Find a Professional</h1>
        </div>
        <!-- / Page title -->

        <!-- Loading Context -->
        <div v-if="isLoadingContext" class="bg-light rounded-3 p-4 p-md-5 mb-3 text-center">
          <spinner></spinner>
        </div>
        <!-- / Loading Context -->

        <!-- Context Loaded -->
        <div v-if="!isLoadingContext && context">
          <!-- Progress -->
          <wizard-progress></wizard-progress>
          <!-- / Progress -->

          <!-- Stages -->
          <div class="stages position-relative">
            <transition-group type="fade">

              <!-- Stage 0 - Profession -->
              <profession-selector v-if="currentStage === 0">
              </profession-selector>
              <!-- / Stage 0 - Profession -->

              <!-- Stage 0 - Speciality -->
              <speciality-selector v-if="currentStage === 1 && !speciality">
              </speciality-selector>

              <subspeciality-selector v-if="currentStage === 1 && speciality">
              </subspeciality-selector>
              <!-- / Stage 0 - Speciality -->

              <!-- Stage 1 - Is Location Important -->
              <is-location-important-selector
                v-if="false && currentStage === 2 && (isLocationImportant === null || !isLocationImportant)">
              </is-location-important-selector>
              <location-selector v-if="false && currentStage === 2 && isLocationImportant"></location-selector>
              <!-- / Stage 1 - Is Location Important -->

              <!--               Stage 2 -->
              <!--              <charges-selector-->
              <!--                  v-if="currentStage === 2"></charges-selector>-->
              <!-- / Stage 2 -->

              <!-- Stage 1 -->
              <review-lawyers v-if="currentStage === 2"></review-lawyers>
              <!-- / Stage 1 -->

              <!-- Stage 3 -->
              <provide-job-details v-if="currentStage === 3"></provide-job-details>
              <!-- / Stage 3 -->

              <!-- Stage 4 -->
              <review-job v-if="currentStage === 4"></review-job>
              <!-- / Stage 4 -->

            </transition-group>
          </div>
          <!-- / Stages -->
        </div>
        <!-- / Context Loaded -->
      </div>
    </div>
    <!-- / Page content -->
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import WizardProgress from "./FindALawyer/WizardProgress.vue";
import SpecialitySelector from "./FindALawyer/Stages/SpecialitySelector.vue";
import { mapGetters, mapActions } from "vuex";
import ProfessionSelector from "./FindALawyer/Stages/ProfessionSelector.vue";
import SubspecialitySelector from "./FindALawyer/Stages/SubspecialitySelector";
import IsLocationImportantSelector from "./FindALawyer/Stages/IsLocationImportantSelector";
import LocationSelector from "./FindALawyer/Stages/LocationSelector";
// import ChargesSelector from "./FindALawyer/Stages/ChargesSelector";
import ReviewLawyers from "./FindALawyer/Stages/ReviewLawyers";
import Spinner from "../../components/Spinner";
import RequiresContext from "../../mixins/RequiresContext";
import ProvideJobDetails from "./FindALawyer/Stages/ProvideJobDetails";
import ReviewJob from "./FindALawyer/Stages/ReviewJob";
import LoadingScreen from "../../components/LoadingScreen";

export default {
  mixins: [RequiresContext],
  components: {
    LoadingScreen,
    ReviewJob,
    LocationSelector,
    ProfessionSelector,
    IsLocationImportantSelector, SubspecialitySelector, Breadcrumb, WizardProgress, SpecialitySelector,
    ReviewLawyers,
    Spinner,
    ProvideJobDetails
  },
  data() {
    return {
      crumbs: [
        {
          href: "/",
          label: "Home",
        },
        {
          href: "#",
          label: "Find a Professional",
          active: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('findALawyer', {
      context: 'context',
      currentStage: 'currentStage',
      speciality: 'speciality',
      isLocationImportant: 'isLocationImportant',
      isSaving: 'isSaving'
    })
  },
  created() {
    // this.reset();
  },
  methods: {
    ...mapActions('findALawyer', {
      setContext: 'setContext',
      initialiseBudgetFilter: 'initialiseBudgetFilter',
      reset: 'reset'
    }),

    onContextLoaded(context) {
      this.setContext(context);
      this.initialiseBudgetFilter();
    }
  }
};
</script>

<style></style>