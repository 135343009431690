<template>
  <div class="lawyer-card bg-secondary rounded-3 p-4 card-hover" @click.prevent="$emit('view-more-details-for', lawyer)">
    <div class="left">
      <!--      <img class="d-none d-sm-block rounded-circle" :src="getAvatarSrc(lawyer)" width="80" :alt="lawyer.name">-->
      <user-role-avatar class="d-none d-sm-block rounded-circle"
                        :user-role="lawyer" width="80"></user-role-avatar>
    </div>
    <div class="right">
      <b>{{ lawyer.name }}</b>
      <p class="mt-1 mb-0 pb-1 small">
        {{ lawyer.bio }}
      </p>
    </div>
  </div>
</template>

<script>
import Images from "../../../../../mixins/Images";
import UserRoleAvatar from "../../../../../components/UserRoleAvatar";

export default {
  name: "LawyerCard",
  components: {UserRoleAvatar},
  props: ['lawyer'],
  mixins: [Images]
}
</script>

<style lang="scss" scoped>
.lawyer-card {
  display: flex;
  flex-direction: row;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;

  &:hover {
    //background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
  }

  .left {
    width: 100px;
    min-width: 100px;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .right {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
  }
}
</style>