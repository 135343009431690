<template>
  <div class="lawyer-result-card bg-light rounded-3 p-4">
    <div class="left">
<!--      <img class="d-none d-sm-block rounded-circle" :src="getAvatarSrc(lawyer)" width="80" :alt="lawyer.name">-->
      <user-role-avatar class="d-none d-sm-block rounded-circle" :user-role="lawyer" width="80"></user-role-avatar>
    </div>
    <div class="middle">
      <div v-if="lawyer.demo_mode || isGreatMatch">
        <span v-if="lawyer.demo_mode" class="badge bg-faded-info mb-1 me-2">Demo</span>
        <span v-if="lawyer.premium" class="badge bg-faded-dark mb-1 me-2">Premium</span>
        <span v-if="isGreatMatch" class="badge bg-faded-primary mb-1 me-2">Great Match</span>
      </div>
      <b>
        {{ lawyer.name }}
        <toggle-favourited-button v-if="loggedIn" :user-role="lawyer" class="ms-2"></toggle-favourited-button>
        <has-video-embed-indicator v-if="lawyer.video_embed_url" class="ms-2" :user-role="lawyer"></has-video-embed-indicator>
<!--        <is-favourite-indicator :user-role="lawyer" class="ms-2"></is-favourite-indicator>-->
      </b>
      <p class="mt-1 mb-0 pb-1 small">
        {{ lawyer.bio }}
      </p>
    </div>
    <div class="right">
      <a href="#"
         @click.prevent.stop="$emit('view-more-details', lawyer)"
         class="btn btn-secondary btn-sm me-2">More Details</a>

      <a href="#"
         @click.prevent.stop="handleToggleLawyerSelectedButton"
         class="btn btn-primary btn-sm">{{ isLawyerSelected ? 'Deselect' : 'Select' }}</a>
<!--      <span v-if="isLawyerSelected" class="badge bg-primary">Selected</span>-->
    </div>
  </div>
</template>

<script>
import Images from "../../../../../../mixins/Images";
import {mapGetters, mapActions} from "vuex";
import IsFavouriteIndicator from "./LawyerResultCard/IsFavouriteIndicator";
import UserRoleAvatar from "../../../../../../components/UserRoleAvatar";
import ToggleFavouritedButton from "./LawyerMoreDetailsModal/ToggleFavouritedButton";
import HasVideoEmbedIndicator from "./LawyerResultCard/HasVideoEmbedIndicator";

export default {
  name: "LawyerResultCard",
  components: {HasVideoEmbedIndicator, ToggleFavouritedButton, UserRoleAvatar, IsFavouriteIndicator},
  props: ['lawyer', 'matchPercentage'],
  mixins: [Images],
  computed: {
    ...mapGetters('findALawyer', {
      lawyersSelected: 'lawyersSelected'
    }),

    ...mapGetters('auth', {
      loggedIn: 'loggedIn'
    }),

    isLawyerSelected() {
      return _.find(this.lawyersSelected, {id: this.lawyer.id}) !== undefined;
    },

    isGreatMatch() {
      return this.matchPercentage >= 90;
    }
  },
  methods: {
    ...mapActions('findALawyer', {
      toggleLawyerSelected: 'toggleLawyerSelected'
    }),

    handleToggleLawyerSelectedButton() {
      this.toggleLawyerSelected(this.lawyer)
    }
  }
}
</script>

<style scoped lang="scss">
//.lawyer-result-card:hover {
//  cursor: pointer;
//}

.lawyer-result-card {
  display: flex;
  flex-direction: row;

  .left {
    width: 100px;
    min-width: 100px;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .middle {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .right {
    flex-shrink: 1;
    display: flex;
    //flex-direction: column;
    //justify-content: center;
    align-items: center;
  }
}
</style>